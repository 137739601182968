body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin; 
  scrollbar-color: transparent transparent;
}

code {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: white; /* Background color of the track */
}

::-webkit-scrollbar-thumb {
  background: white; /* Color of the thumb */
  border-radius: 5px; /* Radius of the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: black; /* Hover color of the thumb */
}
@tailwind base;
@tailwind components;
@tailwind utilities;


#trending-cat-card:hover{
#trending-cat-card-img{
  filter: brightness(.7);
}
  #trending-cat-text{
    bottom:400px;
    /* color:red; */
    transition: bottom 0.5s ease;
 }
 #trending-cat-text .paragraph,button{
  display:block
 }

}

#trending-cat-card-2:hover{
  #trending-cat-card-img-2{
    filter: brightness(.7);
  }
    #trending-cat-text-2{
      bottom:200px;
      /* color:red; */
      transition: bottom 0.5s ease;
   }
   #trending-cat-text-2 .paragraph-2,button-2{
    display:block
   }
  
  }

  #popular-destination-card{
    #popular-destination-card-image{
      filter: brightness(.5)
    }
    
  }

#popular-destination-card:hover{
  #popular-destination-card-image{
    filter: brightness(1)
  }
  #popular-destination-card-text{
     bottom:200px;
     transition: bottom 0.5s ease;
  }
}
@media (max-width: 600px) {
  #popular-destination-card:hover {
    #popular-destination-card-text {
      bottom: 120px;
      transition: bottom 0.5s ease;
    }
  }
}



.App {
  font-family: Arial, Helvetica, sans-serif;
  max-width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h1,
h2,
h3,
p,
li {
  padding: 0;
  margin: 0;
  line-height: 1;
}

h1 {
  font-size: 18px;
}

h2 {
  font-size: 16px;
}

h3 {
  font-size: 12px;
}

p {
  font-size: 12px;
}

.info {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}


.container__slider {
  position: relative;
  width: 100%;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: black;
}

.container__slider > button {
  background-color: transparent;
  outline: none;
  border: 0;
  color: whitesmoke;
  font-size: 24px;
}

.container__slider > button:active {
  color: black;
}

.slider__btn-next {
  position: absolute;
  right: 0;
}

.slider__btn-prev {
  position: absolute;
  left: 5px;
}

.container__slider__links {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.container__slider__links-small {
  position: relative;
  width: 8px;
  height: 8px;
  padding: 0;
  border-radius: 50%;
  outline: none;
  border: 0;
  background-color: white;
  border: 1px solid grey;
  transition: background-color 1s ease;
  margin: 0 2px;
}

.container__slider__links-small-active {
  background-color: black;
}

.slider__item {
  box-sizing: border-box;
  min-width: 100%;
  height: auto;
  overflow: hidden;
  object-fit: cover;
  height: auto;
  transform: translateX(0);
  transition: transform 1s ease;
}

.slider__item-active-1 {
  transform: translateX(0);
}

.slider__item-active-2 {
  transform: translateX(-100%);
}

.slider__item-active-3 {
  transform: translateX(-200%);
}

.slider__item-active-4 {
  transform: translateX(-300%);
}

.slider__item-active-5 {
  transform: translateX(-300%);
}

.slider__item-active-6 {
  transform: translateX(-300%);
}

.slider__item-active-7 {
  transform: translateX(-300%);
}

.slider__item img {
  width: 100%;
  height: auto;
}
.custom-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 
              0 1px 3px rgba(0, 0, 0, 0.08);
  margin: 10px; /* Add margin to ensure shadow is visible on all sides */
  background-color: #fff; /* Ensure the background color is set */
}

.html-text{
  font-size: 60px !important; /* Use !important to ensure it takes precedence */
  line-height: 1.5;
}

.custom-description {
  font-size: var(--font-size, 40px) !important; /* Use !important to ensure it overrides other styles */
}

.rtl {
  direction: rtl;
}
.rtl-transform {
  transform: scaleX(-1); /* Flips the image horizontally */
}

.description-container {
  font-size: 17px;
  line-height: 2;
}

@media (max-width: 640px) {
  .description-container {
    font-size: 10px;
    line-height: 1.5;
  }
}

.responsive-text {
  font-size: 17px;
  line-height: 2;
}

@media (max-width: 640px) {
  .responsive-text {
    font-size: 10px;
    line-height: 1.5;
  }
}

.banner-container {
  position: relative;
  width: 100%;
  height: 300px; /* Adjust based on your banner size */
  overflow: hidden;
}

.shimmer-placeholder {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    #f0f0f0 25%,
    #e0e0e0 50%,
    #f0f0f0 75%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease-in-out;
}

.banner-image.hidden {
  opacity: 0;
}

.banner-image.visible {
  opacity: 1;
}


.override-html * {
  font-size: 20px !important;
  line-height: 1.2 !important; /* Adjust this value as needed */
}
.override-htmlb * {
  font-size: 40px !important;
  font: italic;
}
.override-htmlss * {
  font-size: 17px !important;
  line-height: 1.5 !important; /* Adjust this value as needed */
}
.override-htmlaa * {
  font-size: 16px !important;
  line-height: 1.3 !important; /* Adjust this value as needed */
}

@media (max-width: 640px) {
  .override-html * {
    font-size: 15px !important;
    line-height: 1.2 !important; /* Adjust this value as needed for smaller screens */
  }
}

.override-html desc * {
  font-size: 18px !important;
  line-height: 1.2 !important; /* Adjust this value as needed */
}

@media (max-width: 640px) {
  .override-html desc * {
    font-size: 10px !important;
    line-height: 1.2 !important; /* Adjust this value as needed for smaller screens */
  }
}

.override-htmlq * {
  font-size: 18px !important;
  line-height: 1.2 !important; /* Adjust this value as needed */
}

@media (max-width: 640px) {
  .override-htmlq * {
    font-size: 16px !important;
    line-height: 1.2 !important; /* Adjust this value as needed for smaller screens */
  }
}

.override-htmls * {
  font-size: 16px !important;
  line-height: 1.2 !important; /* Adjust this value as needed */
}

@media (max-width: 640px) {
  .override-htmls * {
    font-size: 14px !important;
    line-height: 1 !important; /* Adjust this value as needed for smaller screens */
  }
}



